import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

type FeatureFlags = {
  global: {
    darkMode: boolean;
  };
};

export const initialValue: FeatureFlags = {
  global: {
    darkMode: false,
  },
};

const featureFlagsAtom = atomWithStorage<FeatureFlags>(
  "WellDeliveryWorkspace-PreviewFeatures",
  initialValue
);

export default function useFeatureFlags() {
  return useAtom(featureFlagsAtom);
}

import { Card } from "@mui/joy";
import { styled } from "@mui/joy/styles";

const CustomCard = styled(Card)(({ theme }) => ({
  "--Input-focused": "0",
  "--Input-focusedThickness": theme.vars.focus.thickness,
  "&::before": {
    boxSizing: "border-box",
    content: '""',
    display: "block",
    position: "absolute",
    pointerEvents: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    borderRadius: "inherit",
    margin: "calc(var(--variant-borderWidth, 0px) * -1)", // for outlined variant
    boxShadow: `var(--Input-focusedInset, inset) 0 0 0 calc(var(--Input-focused) * var(--Input-focusedThickness)) var(--Input-focusedHighlight)`,
  },
  ":focus-within::before": { "--Input-focused": "1" },
}));

export default CustomCard;

export type TranslationsType<T extends readonly string[]> = Record<
  T[number],
  string
>;

export function defaultTranslations<T extends string>(keys: readonly T[]) {
  return keys.reduce(
    (acc, key) => {
      acc[key] = key;
      return acc;
    },
    {} as Record<T, T>
  );
}

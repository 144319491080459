import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "hexagon-exclamation";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M4.53125 13.125C4.125 12.4375 4.125 11.5938 4.53125 10.875L7.28125 6.125C7.6875 5.40625 8.4375 5 9.21875 5H14.75C15.5625 5 16.2812 5.40625 16.6875 6.125L19.4375 10.875C19.8438 11.5938 19.8438 12.4375 19.4375 13.125L16.6875 17.9062C16.2812 18.625 15.5625 19.0312 14.75 19.0312H9.21875C8.4375 19.0312 7.6875 18.625 7.28125 17.9062L4.53125 13.125ZM5.8125 11.625C5.6875 11.875 5.6875 12.1562 5.8125 12.375L8.59375 17.1562C8.71875 17.4062 8.96875 17.5312 9.21875 17.5312H14.75C15 17.5312 15.25 17.4062 15.4062 17.1562L18.1562 12.375C18.2812 12.1562 18.2812 11.875 18.1562 11.625L15.4062 6.875C15.25 6.625 15 6.5 14.75 6.5H9.21875C8.96875 6.5 8.71875 6.625 8.5625 6.875L5.8125 11.625ZM12 8C12.4062 8 12.75 8.34375 12.75 8.75V12.25C12.75 12.6875 12.4062 13 12 13C11.5625 13 11.25 12.6875 11.25 12.25V8.75C11.25 8.34375 11.5625 8 12 8ZM11 15C11 14.4688 11.4375 14 12 14C12.5312 14 13 14.4688 13 15C13 15.5625 12.5312 16 12 16C11.4375 16 11 15.5625 11 15Z";
const prefix = "fac";

export const HexagonExclamation: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(HexagonExclamation);

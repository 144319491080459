import * as echarts from "echarts/core";
import {
  useEffect,
  useMemo,
  useRef,
  useState,
  type ComponentProps,
} from "react";
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
} from "echarts/components";
import EChartsRenderer from "../../helpers/ECharts/EChartsWrapper";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import type { EChartsOption } from "echarts";
import { Grid, Typography, useColorScheme, useTheme } from "@mui/joy";
import {
  defaultTranslations,
  type TranslationsType,
} from "library-translations";
import colors from "../../tokens/colors";
import useTooltipContainer from "../../helpers/useTooltipContainer";
import TooltipFormatter from "./TooltipFormatter";
import type ReactEChartsCore from "echarts-for-react/lib/core";
import FeedbackMessage from "../FeedbackMessage/FeedbackMessage";

const translationStrings = [
  "Loading...",
  "No Data Available",
  "Depth (m)",
  "Error on loading data",
  "planned",
  "Actual Depth",
  "Planned Depth",
  "Days",
  "Productive time",
  "Time Depth Graph",
  "Nothing here yet!",
  "Looks like there's no data right now.\nPlease check your data source.",
  "Error",
  "We encountered an issue while trying to \ndisplay your Depth Graph. Please refresh the \npage or try again later.",
] as const;

export interface WellboreTimeDepthGraphProps {
  data?: {
    actual: [
      date: number,
      depth: number,
      timestamp: Date | string,
      description: string,
      status: "PRT" | "NPT" | "WOW",
    ][];
    planned: [date: number, depth: number][];
  };
  isLoading?: boolean;
  isError?: boolean;
  translations?: TranslationsType<typeof translationStrings>;
  renderer?: NonNullable<
    ComponentProps<typeof EChartsRenderer>["opts"]
  >["renderer"];
}

const colorCategories = [
  "alphaBlue",
  "alphaLightGreen",
  "alphaOrange",
  "alphaPurple",
] as const;

const colorShades = [800, 600, 400] as const;

type DefaultColorShades = (typeof colorShades)[number];

type ColorShades = Record<DefaultColorShades, string>;

const colorsArray: string[] = colorCategories.flatMap((category) =>
  colorShades.map((shade) => (colors[category] as ColorShades)[shade])
);

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

export default function WellboreTimeDepthGraph({
  isLoading,
  data,
  translations,
  isError = false,
  renderer,
}: WellboreTimeDepthGraphProps) {
  const theme = useTheme();
  const t = useMemo(
    () => ({ ...defaultTranslations(translationStrings), ...translations }),
    [translations]
  );
  const { colorScheme } = useColorScheme();
  const tooltipContainer = useTooltipContainer();
  const echartsRef = useRef<ReactEChartsCore>(null);
  const [selectedStatus, setSelectedStatus] = useState<{
    PRT: boolean;
    NPT: boolean;
    WOW: boolean;
  }>({
    PRT: true,
    NPT: true,
    WOW: true,
  });
  const [selectedLegend, setSelectedLegend] = useState<Record<string, boolean>>(
    { [t["Planned Depth"]]: true }
  );

  useEffect(() => {
    if (!echartsRef.current) return;
    const echartsInstance = echartsRef.current.getEchartsInstance();
    const dateRangeSubscription = echartsInstance.on(
      "datarangeselected",
      (params) => {
        const { selected } =
          params && typeof params === "object" && "selected" in params
            ? (params as { selected: typeof selectedStatus })
            : {};
        if (!selected) return;
        setSelectedStatus(selected);
      }
    );
    const legendSubscription = echartsInstance.on(
      "legendselectchanged",
      (params) => {
        const { selected } =
          params && typeof params === "object" && "selected" in params
            ? (params as { selected: typeof selectedLegend })
            : {};
        if (!selected) return;
        setSelectedLegend(selected);
      }
    );
    return () => {
      dateRangeSubscription.off("datarangeselected");
      legendSubscription.off("legendselectchanged");
    };
  }, []);

  const options: EChartsOption = useMemo(() => {
    const seriesMetadata = {
      actual: {
        title: t["Actual Depth"],
        colors: {
          PRT: colors["alphaLightGreen"][800],
          NPT: colors["alphaOrange"][800],
          WOW: colors["alphaPurple"][800],
        },
      },
      planned: {
        title: t["Planned Depth"],
        color: colors["alphaBlue"][800],
      },
    };

    return {
      color: colorsArray,
      tooltip: {
        trigger: "axis",
        borderWidth: 0,
        backgroundColor: "transparent",
        extraCssText: "box-shadow: none;",
        formatter: (params) => {
          const _params = Array.isArray(params) ? params : [];
          const filteredParams = _params
            .map((param) =>
              Array.isArray(param.value) &&
              (!param.value[4] ||
                selectedStatus[param.value[4] as "PRT" | "NPT" | "WOW"])
                ? param
                : null
            )
            .filter((param) => param !== null);
          return tooltipContainer(TooltipFormatter, filteredParams);
        },
      },
      xAxis: {
        name: t["Days"],
        nameGap: -26,
        showGrid: false,
        splitLine: {
          show: false,
        },
        nameLocation: "middle",
        type: "value" as const,
        axisLine: { onZero: false, show: false },
        axisTick: {
          show: false,
        },
        offset: 50,
        axisLabel: {
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
          formatter: function (value: string) {
            return value.toString();
          },
        },
      },
      yAxis: {
        name: t["Depth (m)"],
        nameTextStyle: {
          fontFamily: "Inter",
          fontWeight: 300,
          fontSize: 12,
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
        nameLocation: "middle",
        nameGap: 98,
        inverse: true,
        min: 0,
        type: "value" as const,
        axisLine: { onZero: false, show: false },
        splitLine: {
          lineStyle: {
            type: "dotted",
          },
        },
        axisLabel: {
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
          margin: 40,
        },
      },
      ...(data && {
        dataZoom: [
          {
            type: "slider",
            startValue: 0,
            endValue: data.actual.length - 1,
            filterMode: "empty",
            showDataShadow: false,
            showDetail: false,
            handleSize: "100%",
            height: 20,
            bottom: 50,
            fillerColor: "#7A8FA526",
            borderRadius: 7,
            borderColor: "#7A8FA526",
            handleIcon:
              "path://M0 3C0 1.3432 1.3432 0 3 0H9c1.6569 0 3 1.3432 3 3V13c0 1.6569-1.3431 3-3 3H3c-1.6568 0-3-1.3431-3-3V3ZM4 4.5c0-.2761.2239-.5.5-.5s.5.2239.5.5v7c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-7Zm3 0c0-.2761.2239-.5.5-.5s.5.2239.5.5v7c0 .2761-.2239.5-.5.5s-.5-.2239-.5-.5v-7Z",
          },
        ],
      }),
      grid: {
        left: 40,
        right: 10,
        containLabel: true,
      },
      legend: {
        show: true,
        data: [seriesMetadata.planned.title],
        icon: "path://M2,0 A2,2 0 0 1 4,0 L8,0 A2,2 0 0 1 10,2 L10,8 A2,2 0 0 1 8,10 L2,10 A2,2 0 0 1 0,8 L0,2 A2,2 0 0 1 2,0 Z",
        itemStyle: {
          borderType: "dashed",
          borderWidth: 1,
          borderColor: colors["alphaBlue"][800],
          borderRadius: 3,
          color: "transparent",
        },
        top: 0,
        right: 220,
        itemWidth: 16,
        itemHeight: 16,
        formatter: (name) => {
          return `{name|${name}}`;
        },
        textStyle: {
          rich: {
            name: {
              overflow: "truncate",
              fontWeight: 300,
              fontSize: 10,
              lineHeight: 15,
              fontFamily: "Inter",
              color:
                colorScheme === "dark"
                  ? colors["grey"][100]
                  : colors["grey"][800],
            },
          },
        },
        inactiveColor: "transparent",
        inactiveBorderWidth: 1,
        inactiveBorderColor: colors["grey"][300],
        selected: selectedLegend,
      },
      visualMap: [
        {
          selected: selectedStatus,
          seriesIndex: 1,
          categories: ["PRT", "NPT", "WOW"],
          formatter: (value) => {
            return value === "PRT"
              ? t["Productive time"]
              : (value?.toString() ?? "");
          },
          inRange: {
            color: [
              colors["alphaLightGreen"][800],
              colors["alphaOrange"][800],
              colors["alphaPurple"][800],
            ],
          },
          right: 0,
          top: 0,
          itemWidth: 16,
          itemHeight: 16,
          itemGap: 15,
          textGap: 5,
          orient: "horizontal" as const,
          textStyle: {
            overflow: "truncate",
            fontWeight: 300,
            fontSize: 10,
            lineHeight: 15,
            fontFamily: "Inter",
            color:
              colorScheme === "dark"
                ? colors["grey"][100]
                : colors["grey"][800],
          },
        },
      ],
      title: {
        top: 0,
        left: 0,
        text: t["Time Depth Graph"],
        textStyle: {
          fontWeight: 300,
          fontSize: 20,
          fontFamily: "Inter",
          lineHeight: 24,
          color:
            colorScheme === "dark" ? colors["grey"][100] : colors["grey"][800],
        },
      },
      ...(data && {
        series: [
          {
            data: data.planned,
            name: seriesMetadata.planned.title,
            type: "line" as const,
            lineStyle: {
              width: 2,
              type: "dotted",
              color: seriesMetadata.planned.color,
            },
            itemStyle: {
              borderType: "dotted",
              color: seriesMetadata.planned.color,
            },
            showSymbol: false,
          },
          {
            data: data.actual,
            name: seriesMetadata.actual.title,
            type: "line" as const,
            lineStyle: {
              opacity: 0,
            },
            showSymbol: false,
          },
          // This series exists just to make the lines of the right color
          {
            name: seriesMetadata.actual.title,
            type: "lines",
            coordinateSystem: "cartesian2d",
            selectedMap: selectedStatus,
            clip: true,
            data: data.actual
              .slice(0, -1)
              .map(([date, depth, , , status], index) => ({
                coords: [
                  [date, depth],
                  [data.actual[index + 1][0], data.actual[index + 1][1]],
                ],
                lineStyle: {
                  width: 2,
                  color: seriesMetadata.actual.colors[status],
                  opacity: selectedStatus[status] ? 1 : 0,
                  join: "round" as const,
                  cap: "round" as const,
                },
                groupId: status,
                showSymbol: false,
              })),
          },
        ],
      }),
    };
  }, [t, colorScheme, selectedLegend, selectedStatus, data, tooltipContainer]);

  if (
    !isLoading && // non sta caricando
    (isError || // è in errore
      !data || // non ci sono dati
      (data.actual.length === 0 && data.planned.length === 0)) // i dati sono vuoti
  ) {
    return (
      <Grid height="100%" container direction="column">
        <Grid>
          <Typography level="h4" fontWeight="sm">
            {t["Time Depth Graph"]}
          </Typography>
        </Grid>
        <Grid
          flexGrow={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isError ? (
            <FeedbackMessage
              title={t["Error"]}
              description={
                t[
                  "We encountered an issue while trying to \ndisplay your Depth Graph. Please refresh the \npage or try again later."
                ]
              }
              isError
            />
          ) : (
            <FeedbackMessage
              title={t["Nothing here yet!"]}
              description={
                t[
                  "Looks like there's no data right now.\nPlease check your data source."
                ]
              }
            />
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <EChartsRenderer
      ref={echartsRef}
      notMerge={true}
      lazyUpdate={true}
      echarts={echarts}
      option={options}
      style={{ height: "100%", width: "100%" }}
      loadingOption={{
        text: t["Loading..."],
        color: "gray",
        textColor: theme.palette.common.black,
        maskColor: "rgba(255, 255, 255, 0.8)",
        showSpinner: true,
        spinnerRadius: 10,
        fontSize: 16,
        fontWeight: 300,
        fontFamily: "Inter",
      }}
      showLoading={isLoading}
      opts={{ renderer }}
    />
  );
}

import { stackNames, type StackName } from "library-infra";

export default function getCurrentStackName() {
  let currentStack = import.meta.env.VITE_STACK_NAME as
    | StackName
    | "local"
    | undefined;
  if (!currentStack && import.meta.env.DEV) currentStack = "local";
  if (!currentStack) throw new Error("VITE_STACK_NAME is not defined");
  if (![...stackNames, "local"].includes(currentStack))
    throw new Error("Invalid stack name");
  return currentStack;
}

/**
 * Check if the given value is a valid ref.
 *
 * @param x - The value to check.
 * @returns True if the value is a valid ref, false otherwise.
 *
 * @example
 *
 * ```ts
 * const ref = React.createRef();
 *
 * const result0 = isValidRef(ref);
 * const result1 = isValidRef(null);
 * const result2 = isValidRef({});
 * const result3 = isValidRef({ current: null });
 *
 * console.log(result0)    // true
 * console.log(result1)   // false
 * console.log(result2)   // false
 * console.log(result3)   // true
 * ```
 */
export function isRef(x: unknown): x is React.RefObject<unknown> {
  return x !== null && typeof x === "object" && "current" in x;
}

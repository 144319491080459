import type { StackName } from "./StackName";

export const stackEnvironments: Record<
  StackName | "local",
  "local" | "development" | "test" | "production"
> = {
  local: "local",
  dev: "development",
  prod: "production",
  varenergidev: "development",
  varenergitest: "test",
  varenergiprod: "production",
};

export type StackEnvironment =
  (typeof stackEnvironments)[keyof typeof stackEnvironments];

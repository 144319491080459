import { Box, CircularProgress } from "@mui/joy";

export function LoadingPage() {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="fixed"
    >
      <CircularProgress size="lg" />
    </Box>
  );
}

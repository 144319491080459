import { InformationSecurity } from "./InformationSecurity";

export const authority = <T extends string>(
  tenantId: T
): `https://login.microsoftonline.com/${T}` =>
  `https://login.microsoftonline.com/${tenantId}`;

export const apps = {
  developerInfo: {
    clientId: "38bfa724-9547-4d68-a7f8-a3883212266f",
    company: "three60",
    tenantId: "269f4069-d8db-4cf0-b02c-b9d3b061710c",
    authority: authority("269f4069-d8db-4cf0-b02c-b9d3b061710c"),
    roles: ["developer", "infra"],
    securityLevel: {
      developer: InformationSecurity.Internal,
      infra: InformationSecurity.Confidential,
    },
  },
  digitalSolutionsPlatformDev: {
    clientId: "6bcfe1e3-206a-4f88-8d3b-da5939fc5544",
    company: "three60",
    tenantId: "269f4069-d8db-4cf0-b02c-b9d3b061710c",
    authority: authority("269f4069-d8db-4cf0-b02c-b9d3b061710c"),
    roles: ["basic", "admin", "extended"],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  digitalSolutionsPlatformProd: {
    clientId: "d1809414-9a68-47d2-892a-3d43bbc2e531",
    company: "three60",
    tenantId: "269f4069-d8db-4cf0-b02c-b9d3b061710c",
    authority: authority("269f4069-d8db-4cf0-b02c-b9d3b061710c"),
    roles: ["basic", "admin", "extended"],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  odpDev: {
    clientId: "db805739-44d7-4aa2-a190-109b4907f2e4",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: ["basic", "admin", "extended"],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
  odpProd: {
    clientId: "474b19dd-ef78-4558-97e5-6340865e5307",
    company: "varenergi",
    tenantId: "77da4c42-ba77-462b-bb54-7f7ea57bd0a8",
    authority: authority("77da4c42-ba77-462b-bb54-7f7ea57bd0a8"),
    roles: ["basic", "admin", "extended"],
    securityLevel: {
      basic: InformationSecurity.Internal,
      extended: InformationSecurity.Restricted,
      admin: InformationSecurity.Confidential,
    },
  },
} as const;

export type AppName = keyof typeof apps;

class HttpResponseError extends Error {
  status: number;
  statusText?: string;

  constructor(status: number, statusText?: string, message?: string) {
    super(message);
    this.name = "HttpResponseError";
    this.status = status;
    this.statusText = statusText;
  }
}

export default HttpResponseError;

import { Chip } from "@mui/joy";
import { getCurrentEnvironment } from "library-frontend-utils/infra";

const currentEnvironment = getCurrentEnvironment();

export default function EnvironmentChip({
  environment = currentEnvironment,
}: {
  environment?: typeof currentEnvironment;
}) {
  if (environment === "production") return null;
  return (
    <Chip
      color={environment === "test" ? "warning" : "danger"}
      size="sm"
      sx={{ textTransform: "uppercase" }}
    >
      {environment}
    </Chip>
  );
}

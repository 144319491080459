import { Button, Card, CardContent, Typography } from "@mui/joy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

type ErrorMessageProps = {
  errorTitle: string;
  errorMessage: string | string[];
  onClick?: () => void;
  buttonLabel?: string;
};

function ErrorPageMessage({
  errorTitle,
  errorMessage,
  onClick,
  buttonLabel,
}: ErrorMessageProps) {
  return (
    <Card variant="solid" size="lg" sx={{ width: "50vw" }}>
      <CardContent sx={{ gap: "20px", alignItems: "center" }}>
        <Typography level="h1" color="danger">
          <FontAwesomeIcon icon={faCircleExclamation} />
        </Typography>
        <Typography level="h2">{errorTitle}</Typography>
        {Array.isArray(errorMessage) ? (
          errorMessage.map((message) => (
            <Typography key={message} level="body-lg">
              {message}
            </Typography>
          ))
        ) : (
          <Typography level="body-lg">{errorMessage}</Typography>
        )}
        {onClick && buttonLabel && (
          <Button size="lg" onClick={onClick}>
            {buttonLabel}
          </Button>
        )}
      </CardContent>
    </Card>
  );
}

export default ErrorPageMessage;

import * as Sentry from "@sentry/react";

export default Sentry.feedbackIntegration({
  autoInject: false,
  showBranding: false,
  showName: false,
  showEmail: false,
  formTitle: "Send us Feedback",
  submitButtonLabel: "Send Feedback",
  messageLabel: "Feedback",
  messagePlaceholder: "I wish this feature was available...",
  onFormClose: () => {
    document.body.style.overflow = "";
  },
});

const isHighDpi = matchMedia(
  "(-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2), (min-resolution: 192dpi)"
).matches;

export const standardDPI = {
  sm: 375 + 1,
  md: 768 + 1,
  lg: 1280 + 1,
  xl: 1440 + 1,
} as const;

export const highDPI = {
  sm: 768 + 1,
  md: 1280 + 1,
  lg: 1440 + 1,
  xl: 1920 + 1,
} as const;

const breakpoints = {
  xs: 0,
  ...(isHighDpi ? highDPI : standardDPI),
} as const;

export default breakpoints;

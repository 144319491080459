import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "money-bills-simple";
const width = 24;
const height = 24;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M20 6.5H7C6.71875 6.5 6.5 6.75 6.5 7V14C6.5 14.2812 6.71875 14.5 7 14.5H20C20.25 14.5 20.5 14.2812 20.5 14V7C20.5 6.75 20.25 6.5 20 6.5ZM7 5H20C21.0938 5 22 5.90625 22 7V14C22 15.125 21.0938 16 20 16H7C5.875 16 5 15.125 5 14V7C5 5.90625 5.875 5 7 5ZM16 10.5C16 11.4062 15.5 12.2188 14.75 12.6875C13.9688 13.125 13 13.125 12.25 12.6875C11.4688 12.2188 11 11.4062 11 10.5C11 9.625 11.4688 8.8125 12.25 8.34375C13 7.90625 13.9688 7.90625 14.75 8.34375C15.5 8.8125 16 9.625 16 10.5ZM3.5 7.75V15.25C3.5 16.5 4.5 17.5 5.75 17.5H18.25C18.6562 17.5 19 17.8438 19 18.25C19 18.6875 18.6562 19 18.25 19H5.75C3.65625 19 2 17.3438 2 15.25V7.75C2 7.34375 2.3125 7 2.75 7C3.15625 7 3.5 7.34375 3.5 7.75Z";
const prefix = "fac";

export const MoneyBillsSimple: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(MoneyBillsSimple);

import { type PropsWithChildren } from "react";
import { setUser } from "@sentry/react";
import { useMsal } from "@azure/msal-react";
export default function IdentifyUser({ children }: PropsWithChildren) {
  const { instance } = useMsal();
  const user = instance.getAllAccounts()[0];

  setUser({
    id: user.idTokenClaims?.oid,
    username: user.name,
    email: user.username,
  });

  return children;
}

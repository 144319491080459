export function formatDate(dateToFormat: Date) {
  return new Intl.DateTimeFormat(navigator.language).format(dateToFormat);
}

export function formatDateWithHours(dateToFormat: Date) {
  const formattedDate = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  }).format(dateToFormat);

  return formattedDate;
}

export function formatPercentage(
  percentage: number,
  fractionDigits: number | undefined = 1
) {
  return new Intl.NumberFormat(navigator.language, {
    minimumFractionDigits: fractionDigits,
    style: "percent",
  }).format(percentage);
}

export function truncateText(text: string, maxCharacters: number) {
  return text.length > maxCharacters
    ? text.slice(0, maxCharacters) + "..."
    : text;
}

export function formatNumber(
  num: number,
  fractionDigits: number,
  signDisplay?: keyof Intl.NumberFormatOptionsSignDisplayRegistry
) {
  return new Intl.NumberFormat(navigator.language, {
    maximumFractionDigits: fractionDigits,
    signDisplay,
  }).format(num);
}

export function formatCurrency(
  num: number,
  currency: string,
  currencyDisplay: keyof Intl.NumberFormatOptionsCurrencyDisplayRegistry = "narrowSymbol"
) {
  return new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency,
    currencyDisplay,
  }).format(num);
}

export function cleanQueryParams<T extends object>(
  object: T
): { [P in keyof T]?: NonNullable<T[P]> } {
  const newObj: { [P in keyof T]?: NonNullable<T[P]> } = {};
  for (const key in object) {
    const value = object[key];
    if (value !== null && value !== undefined && value !== "")
      newObj[key] = value;
  }
  return newObj;
}

export function getLocaleDateFormat() {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .formatToParts(new Date())
    .reduce((acc, part) => {
      const { type, value } = part;
      if (type === "year") {
        return acc + "yyyy";
      } else if (type === "month") {
        return acc + "MM";
      } else if (type === "day") {
        return acc + "dd";
      } else {
        return acc + value;
      }
    }, "");
}

import { useMsal } from "@azure/msal-react";

export default function useUserInfo() {
  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];
  if (!account.idTokenClaims) throw new Error("No ID Token retrieved");

  return {
    name: account.name,
    email: account.username,
    roles: account.idTokenClaims.roles ?? [],
  };
}

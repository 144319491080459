import apps from "library-infra/frontend/apps";
import { useMemo } from "react";
import { getCurrentStackName } from "../infra";
import { useUserInfo } from "../auth/hooks";

const currentStack = getCurrentStackName();

/**
 * useApps hook
 * Get the apps that the user has access to
 * @param search {string} string to search for
 * @param sort {"az" | "za"} sort the apps alphabetically
 * @returns {Array} apps
 */
export default function useApps(search?: string, sort?: "az" | "za") {
  const user = useUserInfo();
  return useMemo(() => {
    let filteredApps = apps
      .filter((app) => currentStack in app.url) // Filter out apps that don't have a URL for the current stack
      .filter((app) =>
        app.roles.some((acceptedRole) => user.roles.includes(acceptedRole))
      ) // Filter out apps that the user doesn't have access to
      .map((app) => {
        if (!app.url[currentStack]) throw new Error("URL not found");
        return {
          ...app,
          url: app.url[currentStack],
        };
      }); // Add the URL for the current stack
    // Filter out apps that don't match the search
    filteredApps = search
      ? filteredApps.filter(
          (app) =>
            app.name.toLowerCase().includes(search.toLowerCase()) ||
            app.description.toLowerCase().includes(search.toLowerCase())
        )
      : filteredApps;
    // Sort apps
    filteredApps = sort
      ? filteredApps.sort((a, b) =>
          sort === "az"
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        )
      : filteredApps;
    return filteredApps;
  }, [user.roles, sort, search]);
}

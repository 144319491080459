import type { Theme } from "@mui/joy";
import colors from "../../tokens/colors";

function eChartsDarkTheme(theme: Theme) {
  return {
    color: [
      theme.palette.warning[400],
      colors.purple[400],
      colors.lightGreen[500],
      theme.palette.primary[400],
      colors.pink[400],
      colors.orange[400],
      colors.lime[400],
      colors.moss[400],
    ],
    backgroundColor: theme.palette.neutral[900],
    rowBackground: theme.palette.neutral[200],
    textStyle: {},
    title: {
      textStyle: { color: theme.palette.neutral[200] },
      subtextStyle: { color: theme.palette.neutral[200] },
    },
    line: {
      itemStyle: { borderWidth: 1 },
      lineStyle: { width: 2, color: colors.alphaWhite[200] },
      symbolSize: 4,
      symbol: "circle",
      smooth: false,
    },
    radar: {
      itemStyle: { borderWidth: 1 },
      lineStyle: { width: 2, color: colors.alphaWhite[200] },
      symbolSize: 4,
      symbol: "circle",
      smooth: false,
    },
    bar: {
      itemStyle: {
        barBorderWidth: "0",
        barBorderColor: theme.palette.neutral[400],
      },
    },
    pie: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    scatter: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    boxplot: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    parallel: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    sankey: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    funnel: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    gauge: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
    },
    candlestick: {
      itemStyle: {
        color: colors.aubergine[500],
        color0: colors.lime[300],
        borderColor: colors.aubergine[500],
        borderColor0: colors.lime[300],
        borderWidth: "1",
      },
    },
    graph: {
      itemStyle: { borderWidth: "0", borderColor: theme.palette.neutral[400] },
      lineStyle: { width: "1", color: theme.palette.neutral[100] },
      symbolSize: 4,
      symbol: "circle",
      smooth: false,
      color: [
        theme.palette.warning[400],
        colors.purple[400],
        colors.lightGreen[500],
        theme.palette.primary[400],
        colors.pink[400],
        colors.orange[400],
        colors.lime[400],
        colors.moss[400],
      ],
      label: { color: theme.palette.common.white },
    },
    map: {
      itemStyle: {
        areaColor: theme.palette.neutral[100],
        borderColor: theme.palette.neutral[700],
        borderWidth: 0.5,
      },
      label: { color: theme.palette.common.black },
      emphasis: {
        itemStyle: {
          areaColor: theme.palette.warning[400],
          borderColor: theme.palette.neutral[700],
          borderWidth: 1,
        },
        label: { color: theme.palette.danger[700] },
      },
    },
    geo: {
      itemStyle: {
        areaColor: theme.palette.neutral[100],
        borderColor: theme.palette.neutral[700],
        borderWidth: 0.5,
      },
      label: { color: theme.palette.common.black },
      emphasis: {
        itemStyle: {
          areaColor: theme.palette.warning[400],
          borderColor: theme.palette.neutral[700],
          borderWidth: 1,
        },
        label: { color: theme.palette.danger[700] },
      },
    },
    categoryAxis: {
      axisLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisLabel: {
        show: true,
        color: theme.palette.neutral[100],
        fontFamily: "Inter",
        fontWeight: 300,
        fontSize: 12,
      },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[100]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    valueAxis: {
      axisLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisLabel: {
        show: true,
        color: theme.palette.neutral[100],
        fontFamily: "Inter",
        fontWeight: 300,
        fontSize: 12,
      },
      nameTextStyle: { color: theme.palette.neutral[100] },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[100]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    logAxis: {
      axisLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisLabel: { show: true, color: theme.palette.neutral[100] },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[100]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    timeAxis: {
      axisLine: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisTick: {
        show: true,
        lineStyle: { color: theme.palette.neutral[100] },
      },
      axisLabel: {
        show: true,
        color: theme.palette.neutral[100],
        fontFamily: "Inter",
        fontWeight: 300,
        fontSize: 12,
      },
      nameTextStyle: { color: theme.palette.neutral[100] },
      splitLine: {
        show: true,
        lineStyle: { color: [theme.palette.neutral[100]] },
      },
      splitArea: {
        show: false,
        areaStyle: {
          color: [colors.alphaWhite[200], theme.palette.neutral[200]],
        },
      },
    },
    toolbox: {
      iconStyle: { borderColor: theme.palette.neutral[100] },
      emphasis: { iconStyle: { borderColor: theme.palette.neutral[200] } },
    },
    legend: { textStyle: { color: theme.palette.neutral[200] } },
    tooltip: {
      axisPointer: {
        lineStyle: { color: theme.palette.neutral[200], width: "1" },
        crossStyle: { color: theme.palette.neutral[200], width: "1" },
      },
      textStyle: { color: theme.palette.neutral[800] },
      backgroundColor: theme.palette.neutral[50],
      borderWidth: 1,
      borderColor: theme.palette.neutral[600],
      shadowColor: "transparent",
    },
    timeline: {
      lineStyle: { color: theme.palette.neutral[100], width: "2" },
      itemStyle: { color: theme.palette.neutral[100], borderWidth: 1 },
      controlStyle: {
        color: theme.palette.neutral[100],
        borderColor: theme.palette.neutral[100],
        borderWidth: 1,
      },
      checkpointStyle: {
        color: theme.palette.neutral[200],
        borderColor: theme.palette.common.white,
      },
      label: { color: theme.palette.neutral[100] },
      emphasis: {
        itemStyle: { color: theme.palette.neutral[200] },
        controlStyle: {
          color: theme.palette.neutral[100],
          borderColor: theme.palette.neutral[100],
          borderWidth: 1,
        },
        label: { color: theme.palette.neutral[100] },
      },
    },
    visualMap: {
      color: [
        theme.palette.danger[500],
        theme.palette.warning[500],
        theme.palette.success[500],
      ],
    },
    dataZoom: { handleSize: "undefined%", textStyle: {}, brushSelect: false },
    markPoint: {
      label: { color: theme.palette.common.white },
      emphasis: { label: { color: theme.palette.common.white } },
    },
  };
}

export default eChartsDarkTheme;

import type { ComponentProps } from "react";
import type Indicator from "../AssetCard/Indicator/Indicator";
import { type AssetCardListProps } from "./AssetCardList";

const indicators: ComponentProps<typeof Indicator>[] = [
  {
    title: "Avg. WOW",
    value: 0.4,
    fractionDigits: 0,
    type: "percentage",
  },
  {
    title: "Active Wells",
    value: 112,
    fractionDigits: 0,
    type: "number",
  },
  {
    title: "Interest",
    value: 0.819,
    type: "percentage",
  },
] as const;

export const mockAssetCardList: NonNullable<AssetCardListProps["assets"]> = [
  {
    title: "Volve",
    subtitle: "Licence: P1092",
    color: undefined,
    body: {
      value: 0.15,
      title: "Avg. NPT",
      trend: {
        value: 0.51,
        direction: "up",
      },
    },
    indicators,
  },
  {
    title: "Balder",
    subtitle: "Licence: P027",
    color: "warning",
    body: {
      value: 0.12,
      title: "Avg. NPT",
      trend: {
        value: 0.21,
        direction: "up",
      },
    },
    indicators: [...indicators.slice(0, 2), { ...indicators[2], value: 0.33 }],
  },
  {
    title: "Balder",
    subtitle: "Licence: P028",
    color: "danger",
    body: {
      value: 0.42,
      title: "Avg. NPT",
      trend: {
        value: 0.1922,
        direction: "up",
      },
    },
    indicators,
  },
  {
    title: "Alvheim",
    subtitle: "Licence: K99",
    color: undefined,
    body: {
      value: 0.99,
      title: "Avg. NPT",
      trend: {
        value: 0.1,
        direction: "up",
      },
    },
    indicators,
  },
] as const;

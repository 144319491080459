import { useTheme } from "@mui/joy";
import { useMemo } from "react";
import type { OperationTypes } from "./makeSeries";
import colorsList from "../../../tokens/colors";
type Colors = {
  splitLine: string;
  axisLabel: string;
  operations: Record<OperationTypes, string>;
  operationsHover: Record<OperationTypes, string>;
};

export default function useColors(): Colors {
  const theme = useTheme();

  return useMemo(
    () => ({
      splitLine:
        theme.palette.mode === "dark"
          ? theme.palette.neutral[100]
          : theme.palette.neutral[800],
      axisLabel:
        theme.palette.mode === "dark"
          ? theme.palette.neutral[100]
          : theme.palette.neutral[800],
      operations: {
        Completion: theme.palette.warning[400],
        PlugAbandon: colorsList.purple[400],
        Drilling: colorsList.lightGreen[500],
        Workover: theme.palette.primary[400],
        Interruption: colorsList.pink[400],
        FormationEvaluation: colorsList.orange[400],
        Moving: colorsList.lime[400],
        NotAvailable: colorsList.moss[400],
      },
      operationsHover: {
        Completion: theme.palette.warning[500],
        PlugAbandon: colorsList.purple[500],
        Drilling: colorsList.lightGreen[500],
        Workover: theme.palette.primary[500],
        Interruption: colorsList.pink[500],
        FormationEvaluation: colorsList.orange[500],
        Moving: colorsList.lime[500],
        NotAvailable: colorsList.moss[500],
      },
    }),
    [theme]
  );
}

import type { TopLevelFormatterParams } from "echarts/types/dist/shared";
import { format } from "date-fns";
import { CardContent, Card, Typography } from "@mui/joy";
import useColors from "./helpers/useColors";

type AssetActivityChartTooltipProps = {
  param: TopLevelFormatterParams;
};

const defaultTypographyProps = {
  lineHeight: 1,
  level: "title-md",
} as const;

export default function AssetActivityChartTooltip({
  param,
}: AssetActivityChartTooltipProps) {
  const colors = useColors();

  const data = Array.isArray(param) ? param[0].data : param.data;
  if (!Array.isArray(data) || data[9] !== "") return "";

  const startDate = format(data[1] as string, "dd/MM/yyyy");
  const endDate = format(data[2] as string, "dd/MM/yyyy");
  const seriesName = data[3] as keyof typeof colors.operationsHover;
  const text = data[4] as string;

  return (
    <Card
      sx={{ p: 2, borderRadius: "sm", bgcolor: (t) => t.palette.common.white }}
    >
      <CardContent>
        <Typography
          {...defaultTypographyProps}
          fontWeight="lg"
          sx={{ color: "var(--neutral-solid-Color, #293746)" }}
        >
          {text}
        </Typography>
        <Typography
          {...defaultTypographyProps}
          fontWeight="md"
          sx={{ color: colors.operationsHover[seriesName] }}
        >
          {seriesName}
        </Typography>
        <Typography
          {...defaultTypographyProps}
          fontWeight="sm"
          sx={{ color: "var(--neutral-solid-Color, #293746)" }}
        >
          Start: {startDate}
          <br />
          End: {endDate}
        </Typography>
      </CardContent>
    </Card>
  );
}

import { useState } from "react";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import type { TopLevelFormatterParams } from "echarts/types/dist/shared.js";
import type { ComponentType } from "react";
import ThemeWrapper from "../helpers/ThemeWrapper";

export default function useTooltipContainer() {
  const [tooltipContainer] = useState(() => {
    const element = window.document.createElement("div");
    const root = createRoot(element);
    return { root, element };
  });

  /**
   *
   * @param component The react component that will be rendered in the tooltip
   * @param param the param passed to the formatter
   * @returns the HTML string of the component
   */
  function formatter(
    component: ComponentType<{ param: TopLevelFormatterParams }>,
    param: TopLevelFormatterParams
  ) {
    const Component = component;
    flushSync(() => {
      tooltipContainer.root.render(
        <ThemeWrapper>
          <Component param={param} />
        </ThemeWrapper>
      );
    });
    return tooltipContainer.element.innerHTML;
  }

  return formatter;
}

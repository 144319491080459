import type { PickersShortcutsItem } from "@mui/x-date-pickers";
import type { NonEmptyDateRange } from "@mui/x-date-pickers-pro";
import { startOfYear, subMonths, type Locale } from "date-fns";
import { enUS } from "date-fns/locale";

const getBrowserLocaleCodes = () => {
  const browserLocale = navigator.language;
  const longLocale = browserLocale.replace("-", "");
  const shortLocale = browserLocale.split("-")[0];

  return [shortLocale, longLocale];
};

export const loadLocaleText = async (
  setLocaleText: (localeText: Record<string, unknown>) => void
) => {
  const locale = getBrowserLocaleCodes()[1];
  const localeText = await import("@mui/x-date-pickers/locales").then(
    (module) => module[locale] as Record<string, unknown>
  );
  setLocaleText(localeText);
};

export const loadLocaleFile = async (
  setLocaleFile: (locale: Locale | null) => void
) => {
  const options = getBrowserLocaleCodes();
  const tryImport = async (lang: string) => {
    try {
      const module = await import("date-fns/locale").then(
        (module: Record<string, unknown>) => module[lang] as Locale
      );
      return module;
    } catch {
      return null;
    }
  };

  for (const lang of options) {
    const locale = await tryImport(lang);
    if (locale) {
      setLocaleFile(locale);
      return;
    }
  }
  setLocaleFile(enUS);
};

export const shortcutsItems = (
  t: Record<string, string>
): PickersShortcutsItem<NonEmptyDateRange<Date>>[] => [
  {
    label: t["Last 3 months"],
    getValue: () => {
      return [subMonths(new Date(), 3), new Date()];
    },
  },
  {
    label: t["Last 6 months"],
    getValue: () => {
      return [subMonths(new Date(), 6), new Date()];
    },
  },
  {
    label: t["Last year"],
    getValue: () => {
      return [subMonths(new Date(), 12), new Date()];
    },
  },
  {
    label: t["Year to date"],
    getValue: () => {
      return [startOfYear(new Date()), new Date()];
    },
  },
];

import { IconButton, type Input } from "@mui/joy";
import type { ComponentProps } from "react";
import colors from "../../tokens/colors";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import type { Theme } from "@mui/material";

type EndAdornmentProps = {
  size: ComponentProps<typeof Input>["size"];
  theme: Theme;
  isError?: boolean;
};

export default function EndAdornment({
  size,
  isError,
  theme,
}: EndAdornmentProps) {
  const isDarkMode = theme.palette.mode === "dark";
  const calendarIconColor = isDarkMode
    ? theme.palette.common.white
    : colors.grey[900];

  return (
    <IconButton
      variant="solid"
      size={size}
      sx={{
        backgroundColor: "inherit",
        height: size === "sm" ? "24px" : "32px",
        minWidth: size === "sm" ? "24px" : "32px",
        minHeight: size === "sm" ? "1.25rem" : "1.5rem",
        ":hover": {
          backgroundColor: isDarkMode ? colors.grey[600] : colors.grey[200],
          color: colors.grey[900],
        },
      }}
    >
      <CalendarTodayIcon
        sx={{
          fontSize: size === "sm" ? 14 : 16,
          color: isError ? colors.red[500] : calendarIconColor,
        }}
      />
    </IconButton>
  );
}

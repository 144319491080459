import { type IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useTheme } from "@mui/joy";

function ActivityChartMessage({
  title,
  description,
  icon,
  iconColor,
}: {
  icon: IconDefinition;
  iconColor: string;
  title: string;
  description: string;
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 10,
        textAlign: "center",
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          height: 33,
          width: 33,
          color: iconColor,
          marginBottom: theme.spacing(4),
        }}
      />
      <Typography level="h1" fontWeight={theme.fontWeight.md}>
        {title}
      </Typography>
      <Typography
        level="body-lg"
        fontWeight={theme.fontWeight.sm}
        marginTop={theme.spacing(4)}
      >
        {description}
      </Typography>
    </Box>
  );
}

export default ActivityChartMessage;

import { PublicClientApplication } from "@azure/msal-browser";
import { useMemo } from "react";

export default function usePCA(clientId: string, authority: string) {
  return useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          clientId,
          authority,
        },
      }),
    [authority, clientId]
  );
}

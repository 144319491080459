import { Box, Skeleton, useTheme } from "@mui/joy";

function ActivityChartLoading() {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={theme.spacing(1)}
        marginBottom={theme.spacing(2)}
      >
        <Skeleton
          variant="rectangular"
          width={160}
          height={22}
          sx={{ borderRadius: theme.radius.sm }}
        />
        <Box display="flex" gap={theme.spacing(4)}>
          {new Array(7).fill(null).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={82}
              height={22}
              sx={{ borderRadius: theme.radius.sm }}
            />
          ))}
        </Box>
      </Box>

      {new Array(3).fill(null).map((_, index) => (
        <Box key={index}>
          <Skeleton
            variant="rectangular"
            width={100}
            height={20}
            sx={{
              marginBottom: theme.spacing(2),
              borderRadius: theme.radius.sm,
            }}
          />

          <Skeleton
            variant="rectangular"
            width="100%"
            height={index === 0 ? 160 : 100}
            sx={{
              marginBottom: theme.spacing(1),
              borderRadius: theme.radius.sm,
            }}
          />
        </Box>
      ))}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        gap={40}
      >
        {new Array(3).fill(null).map((_, index) => (
          <Skeleton
            key={index}
            variant="rectangular"
            height={16}
            width={40}
            sx={{
              marginBottom: theme.spacing(2),
              borderRadius: theme.radius.sm,
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
export default ActivityChartLoading;

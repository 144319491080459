import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { library } from "@fortawesome/fontawesome-svg-core";

const iconName = "error";
const width = 34;
const height = 34;
const ligatures = [] as string[];
const unicode = "a";
const svgPathData =
  "M20 36.6673C10.7552 36.6673 3.33337 29.2454 3.33337 20.0007C3.33337 10.821 10.7552 3.33398 20 3.33398C29.1797 3.33398 36.6667 10.821 36.6667 20.0007C36.6667 29.2454 29.1797 36.6673 20 36.6673ZM20 11.6673C19.0886 11.6673 18.4375 12.3835 18.4375 13.2298V20.5215C18.4375 21.4329 19.0886 22.084 20 22.084C20.8464 22.084 21.5625 21.4329 21.5625 20.5215V13.2298C21.5625 12.3835 20.8464 11.6673 20 11.6673ZM22.0834 26.2507C22.0834 25.1439 21.1068 24.1673 20 24.1673C18.8282 24.1673 17.9167 25.1439 17.9167 26.2507C17.9167 27.4225 18.8282 28.334 20 28.334C21.1068 28.334 22.0834 27.4225 22.0834 26.2507Z";
const prefix = "fac";

export const Error: IconDefinition = {
  // @ts-expect-error we're using a custom prefix.
  prefix,
  // @ts-expect-error we're using a custom name.
  iconName,
  icon: [width, height, ligatures, unicode, svgPathData],
};

library.add(Error);

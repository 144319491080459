export const OPERATION_TYPES = [
  "Drilling",
  "Completion",
  "FormationEvaluation",
  "Moving",
  "Interruption",
  "Workover",
  "PlugAbandon",
  "NotAvailable",
] as const;
